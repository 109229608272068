import React from 'react'
import 'twin.macro'

import FormLogo from 'icons/form-logo-gray.svg'
import { ContentImage } from 'mx/components/new/utils'

interface LogosBannerProps {
  logo: ContentImage
}

export const LogosBanner = ({ logo }: LogosBannerProps) => {
  return (
    <div tw="flex flex-row w-full bg-gray-light py-8 md:py-12 pl-6 md:pl-9 space-x-8 items-center">
      <div>
        <FormLogo tw="h-6 w-auto md:h-8" />
      </div>
      <div>
        <svg
          width="1"
          height="37"
          viewBox="0 0 1 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.549805 0.214844L0.549803 36.7076"
            stroke="black"
            strokeWidth="0.409864"
          />
        </svg>
      </div>
      <div>
        <img tw="w-auto h-8 md:h-12" src={logo?.file?.url} />
      </div>
    </div>
  )
}
