import React, { useEffect } from 'react'

import tw from 'twin.macro'

import SEO from 'components/shared/seo'
import useVisitPartner from 'content-queries/mx/visit-partner'
import fireEvent from 'context/tracking/events'
import { HowItWorks, MainContent, TrustedMedia } from 'mx/components/new'
import { LogosBanner } from 'mx/components/new/partners/logos-banner'
import Layout from 'mx/layout'
import { sanitizeUrl } from 'views/utils'

const AdPartner = (props: any) => {
  const { data } = useVisitPartner()
  const [
    {
      node: {
        sectionModules: [
          hero,
          media,
          team,
          timeless,
          performance,
          howItWorks,
          partner,
        ],
      },
    },
  ] = data
  const {
    address,
    adsDynamicBlockImage,
    adsDynamicFooterImage,
    city,
    companyName,
    logo,
    logoWhite,
    partnerCode,
  } = props.pageContext

  useEffect(() => {
    document.body.scrollTo({ top: 0 })
    fireEvent({
      type: 'page_viewed',
      pageTitle: `Visit ${companyName}`,
    })
  }, [])

  return (
    <Layout hideLinks header={false}>
      <LogosBanner logo={logo} />
      <MainContent
        header
        dark
        data={hero}
        partnerAddress={address.address}
        partnerCity={city}
        partnerCode={partnerCode}
        partnerName={companyName}
      />
      <TrustedMedia
        data={media}
        title={'FORM Kitchens, seen in:'}
        classname={tw`!bg-gray-light pt-16 pb-28`}
      />
      <MainContent
        inline
        dark
        data={team}
        customImage={adsDynamicBlockImage}
        classname={tw`mt-20 lg:mt-32`}
        partnerCode={partnerCode}
        partnerName={companyName}
      />
      <MainContent
        inline
        data={timeless}
        classname={tw`mt-20 lg:mt-32`}
        partnerCode={partnerCode}
      />
      <MainContent
        inline
        dark
        data={performance}
        classname={tw`!bg-brown-medium mt-20 lg:mt-32`}
        partnerCode={partnerCode}
      />
      <HowItWorks
        affiliateName={companyName}
        data={howItWorks}
        classname={tw`!bg-gray-100 mt-20 lg:mt-32`}
      />
      <MainContent
        dark
        data={partner}
        classname={tw`!bg-brown-medium`}
        customImage={adsDynamicFooterImage}
        partnerAddress={address.address}
        partnerCode={partnerCode}
        partnerLogo={logoWhite}
        partnerName={companyName}
      />
    </Layout>
  )
}

export default AdPartner

export const Head = (props: any) => {
  const { companyName, description, heroImage, publishingUrl } =
    props.pageContext
  const path = `/visit-${publishingUrl || sanitizeUrl(companyName)}`

  return (
    <SEO
      image={
        heroImage.localFile.childImageSharp.gatsbyImageData.images.fallback.src
      }
      title={companyName}
      description={description.description}
      path={path}
    />
  )
}
